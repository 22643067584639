export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const GET_DATA_BEGIN = "GET_DATA_BEGIN";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const POST_DATA_BEGIN = "POST_DATA_BEGIN";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_ERROR = "POST_DATA_ERROR";
export const CHANGE_PATH = "CHANGE_PATH";
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const EDIT_FORM_LOAD = "EDIT_FORM_LOAD";
export const GET_FORM_DATA_BEGIN = "GET_FORM_DATA_BEGIN";
export const GET_FORM_DATA_SUCCESS = "GET_FORM_DATA_SUCCESS";
